<template>
    <div class="modal-basic">
        <div class="title">
            <p>
                <span class="f-medium">{{ agent.name }} 주선자</span>님과
            </p>
            <p>새롭게 시작해봐요!</p>
        </div>
        <div class="new-agent">
            <div class="img-wrapper">
                <!--                <img class="photo" :src="require('@/assets/images/person.svg')" />-->
                <img :src="agent.photo_url" alt="" />
            </div>
            <div class="agent-info">
                <p class="c-primary f-14 f-bold">담당 주선자</p>
                <p class="c-black f-18 f-medium">{{ agent.name }}님</p>
            </div>
        </div>
        <div @click="onClickBtn" class="btn bg-primary">확인했어요!</div>
    </div>
</template>

<script>
export default {
    name: 'ModalMatchNewFriend',
    props: {
        options: Object,
    },
    computed: {
        friend() {
            return this.options.friend
        },
        agent() {
            return this.friend.agent
        },
    },
    methods: {
        onClickBtn() {
            this.$emit('close', { checked: true, chatId: this.friend.chat_id })
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    width: 85vw;

    .title {
        font-size: 24px;
        color: black;
        margin: 2px 0 22px 0;
    }
    .new-agent {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        width: 100%;
        background-color: rgba(#5448cb, 0.08);
        border-radius: 12px;
        z-index: -1;

        .img-wrapper {
            //background-color: $blue-man;
            width: 60px;
            height: 60px;
            margin-right: 20px;
            @include center;

            img {
                width: 100%;
                height: 100%;
                border-radius: 24px;
            }
        }
    }
    .btn {
        margin-top: 32px;
        color: white;
        @include f-medium;
    }
}
</style>
